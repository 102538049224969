<template>
  <navbar>
    <h6 class="title-navbar">
      Akun Saya
    </h6>
  </navbar>

  <div class="profile__wrapper mt-4 p-3">
    <div class="image__wrapper">
      <skeleton v-if="show" width="70px" height="70" class="rounded-circle"/> 
      <img v-else
        :src="accountDetail.photo"
        ref="photoProfil"
        :alt="accountDetail.displayName"
        class="w-100 h-100 rounded-circle"
      />
      <button v-if="!show"
        type="button"
        class="btn btn-sm btn-info"
        style="font-size:10px !important; color:#FFF !important;"
        onclick="document.getElementById('changeImage').click()"
      >
        Ganti Foto
      </button>
      <input
        type="file"
        class="mt-2"
        style="display:none;"
        id="changeImage"
        @change="handleChangeImage"
      />
    </div>
    <div class="information_profile">
      <skeleton v-if="show" width="100" height="100%" />
      <h3 v-else class="profile__name mb-0">
        {{ accountDetail.displayName }}
      </h3>
    </div>
  </div>

  <div class="mt-2 p-3">
    <div v-if="show" class="card card__profile">
      <skeleton width="100" class="card-body card__body-profile" />
    </div>
    <router-link v-else :to="`/profile/${accountDetail.displayName}`" class="card card__profile">
      <div class="card-body card__body-profile">
        Profil
      </div>
    </router-link>

    <div v-if="show" class="card card__profile">
      <skeleton width="100" class="card-body card__body-profile" />
    </div>
    <router-link v-else :to="`/change-password/${accountDetail.displayName}`" class="card card__profile">
      <div class="card-body card__body-profile">
        Password
      </div>
    </router-link>
    <div v-if="accountData.role === 'AL'">
      <div v-if="show" class="card card__profile">
        <skeleton width="100" class="card-body card__body-profile" />
      </div>
      <a v-else :href="`${linkMove}app/sessions/signIn?access_token=${accessToken}&refresh_token=${refreshToken}`" target="_blank" class="card card__profile">
        <div class="card-body card__body-profile">
          Buat Campaign
        </div>
      </a>
    </div>

    <div v-if="show" class="card card__profile">
      <skeleton width="100" class="card-body card__body-profile" />
    </div>
    <div v-else class="card card__profile" @click="handleLogout">
      <div class="card-body card__body-profile">
        Keluar
      </div>
    </div>
  </div>

  <Menu />
</template>

<script>
import { imageCdn } from '@/_helpers'
import swal from 'sweetalert2'

import Navbar from '../../components/TransactionComponent/NavbarTransaction.vue'
import Menu from '../../components/Home/Menu.vue'

import Skeleton from '../../components/Skeleton/Skeleton.vue'

export default {
  components: { Navbar, Menu, Skeleton },
  data() {
    return {
      accessToken: null,
      refreshToken: null,
      accountDetail: {
        displayName: null,
        photo: null
      },
      accountData: {
        role: null
      },
      show: true,
      linkMove: process.env.VUE_APP_URL_MOVE
    }
  },
  mounted() {
    this.isAccessToken()
  },
  methods: {
    isAccessToken() {
      try{
        if (process.browser) {
        if (localStorage.getItem('access_token')) {
          this.accessToken = localStorage.getItem('access_token')
          const accountData = JSON.parse(localStorage.getItem('account_data'))
          this.accountData.role = accountData.role
          // const accountData = JSON.parse(localStorage.getItem('account_data'))
          // this.accountDetail = {
          //   name: accountData.detail.display_name,
          //   photo: accountData.detail.photo_url
          // }
        }

        if (localStorage.getItem('refresh_token')) {
          this.refreshToken = localStorage.getItem('refresh_token')
        }

        this.getAccountDetail()
      }
      }catch(e){
        console.log(e);
        this.$store
        .dispatch('account/logout')
        .then(() => {
          window.location.href = '/login'
          swal.close()
          swal.hideLoading()
        })
        .catch(() => {
          swal.close()
          swal.hideLoading()
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Terjadi kesalahan'
          })
        })
      }
    },
    getAccountDetail() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}me`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        })
        .then(response => {
          this.show = false
          const data = response.data
          if (this.accountData.role === 'AL') {
            this.$http.get(`${process.env.VUE_APP_API_URL}companies/${data.company.company_id}`,{
                headers: {
                  Authorization: `Bearer ${this.accessToken}`
                }
              })
              .then(result => { 
                this.accountDetail = {
                  displayName: data.detail.display_name,
                  photo: result.data.company_logo_url
                }
              })
              .catch(error => {
                swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: error
                })
              })
          }else if(this.accountData.role === 'D'){
            this.accountDetail = {
              displayName: data.detail.display_name,
              photo: data.detail.photo_url
            }
          }
          // this.accountDetail = {
          //   displayName: data.detail.display_name,
          //   photo: this.accountData.role === 'AL' ? this.getImageCompany(data.company.company_id) :  data.detail.photo_url
          // }
        })
        .catch(error => {
           swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error
          })
        })
    },
    // getImageCompany(company_id) {
    //   this.$http.get(`${process.env.VUE_APP_API_URL}companies/${company_id}`,{
    //     headers: {
    //       Authorization: `Bearer ${this.accessToken}`
    //     }
    //   })
    //   .then(response => { 
    //     console.log(response);
    //   })
    //   .catch(error => {
    //     swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: error
    //     })
    //   })
    // },
    handleLogout() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$store
        .dispatch('account/logout')
        .then(() => {
          window.location.href = '/login'
          swal.close()
          swal.hideLoading()
        })
        .catch(() => {
          swal.close()
          swal.hideLoading()
          swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Terjadi kesalahan'
          })
        })
    },
    handleChangeImage($event) {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      const fi = $event.target.files[0]
      const file = Math.round(fi.size / 1024)
      if (file >= 500) {
        swal.close()
        swal.hideLoading()
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'File terlalu besar, harus dibawah 500kb'
        })
      }

      const url = URL.createObjectURL(fi)
      this.$refs.photoProfil.src = url
      const formData = new FormData()
      formData.append('photo', fi)

      this.$http
        .post(`${process.env.VUE_APP_API_URL}me/avatar`, formData, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        })
        .then(response => {
          if (response.status === 200) {
            swal.close()
            swal.hideLoading()
            swal.fire('Berhasil', 'Foto profil berhasil diubah', 'success')
          }
        })
        .catch(error => {
          swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error
          })
        })
    }
  }
}
</script>
